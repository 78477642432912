<template>
  <main>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="auth-header">
            <buttonLink 
         
         :destination="'/'"
           :vegangLogo="true"
            >
           </buttonLink>
            <button-link
            :isInternal="true"
                :content="' Sign-up as dropshipper'"
                :destination="'/register/retailer'"
                :filled="true"
                >
                </button-link>
            
          </div>
        </div>
      </div>
    </div>
    <div class="auth-page for-retailer">
      <div class="authpage-header">
        <h1 class="title for-retailer">
          <span class="green-text">Hi, </span>Welcome back
          <span class="sub for-retailer"
            >Did you forget your password? No worries! Send a request to reset
            you password.
          </span>
        </h1>
      </div>

      <div v-if="!isSuccessful" class="auth-box">
        <h2 class="form-title">Enter your new password</h2>
        <div class="errorMessage-container" v-if="showError">
          <div class="material-icons error-icon">cancel</div>
          <div>
            Ops! something happened
            <span>{{ errorMessage }}</span>
          </div>
        </div>
        <div class="form-body">
          <div class="form">
            <CustomPasswordInput
              type="password"
              id="new_password"
              icon="key"
              label="New password"
              v-model="resetPwd.newPassword"
              :class="hasError ? 'hasError' : ''"
            />
            <CustomPasswordInput
              type="password"
              id="confirm_new_password"
              icon="key"
              label="Confirm password"
              v-model="resetPwd.confirmNewPassword"
              :class="hasError ? 'hasError' : ''"
            />
            <button
              class="vgang-button form-button"
              @click="handleResetPwdRequest"
            >
              Send
            </button>
          </div>
        </div>
        <div class="box-footer">
          <span class="footer-text"
            >Do you want to be a dropshipper?
            <buttonLink
        :content="' Become a dropshipper'"
        :destination="'/register/retailer'"
        :greenText="true"
       ></buttonLink>
           
          </span>
        </div>
      </div>
      <div v-else class="auth-box">
        <h2 class="form-title">{{ successMessage }}</h2>
        <div class="errorMessage-container" v-if="showError">
          <div class="material-icons error-icon">cancel</div>
          <div>
            Ops! something happened
            <span>{{ errorMessage }}</span>
          </div>
        </div>
        <div class="form-body">
          <div class="form">
            <router-link :to="'/register/retailer'">
              <button class="vgang-button form-button">Go to Login</button>
            </router-link>
          </div>
        </div>
        <div class="box-footer">
          <span class="footer-text"
            >Do you want to be a dropshipper?
            <buttonLink
        :content="' Become a dropshipper'"
        :destination="'/register/retailer'"
        :greenText="true"
       ></buttonLink>
           
          </span>
        </div>
      </div>
      <img src="@/assets/img/retailer--bg.svg" alt="auth" class="auth-shape" />
    </div>
  </main>
</template>

<script>
import CustomInput from "@/components/CustomInput.vue";
import CustomPasswordInput from "@/components/CustomPasswordInput.vue";
import buttonLink from "@/components/bases/ButtonLink.vue";
export default {
  metaInfo: {
    title: "vgang|reset-password-retailer",
  },
  data() {
    return {
      showError: false,
      hasError: false,
      errorMessage: "",
      isSuccessful: false,
      successMessage: "",
      resetPwd: {
        newPassword: "",
        confirmNewPassword: "",
      },
    };
  },
  components: {
    CustomInput,
    CustomPasswordInput,
    buttonLink
  },
  methods: {
    handleResetPwdRequest() {
      if (this.resetPwd.newPassword !== this.resetPwd.confirmNewPassword) {
        this.showError = true;
        this.hasError = true;
        this.errorMessage =
          "Please check your password and the confirm password";

        return;
      } else {
        this.$store
          .dispatch("auth/resetPassword", {
            password: this.resetPwd.newPassword,
            token: this.$route.query.token,
          })
          .then(
            (data) => {
              this.isSuccessful = true;
              this.successMessage =
                "Your password has been reset. You can now login with your new password.";
            },
            (error) => {
              this.successful = false;
              this.showAuthError = true;
              this.errorMessage =
                "Could not reset your password. Please try again later.";
              console.log(error);
            }
          );
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.token) {
      next({ name: "Home" });
    }
    next();
  },
};
</script>

<style></style>
